import PropTypes from 'prop-types';
import Link from 'next/link';

const Quote = ({ quote, attribution, url }) => {
  return (
    (<Link href={url} className="Quote">

      <figure>
        <blockquote className="Quote-content" cite={url}>
          <p className="u-mB-0 small">&ldquo;{quote}&rdquo;</p>
        </blockquote>
        <figcaption className="Quote-citation">
          — <cite>{attribution}</cite>
        </figcaption>
        <span className="Quote-icon">
          <span className="icon icon-users" />
        </span>
      </figure>

    </Link>)
  );
};

Quote.propTypes = {
  quote: PropTypes.string.isRequired,
  attribution: PropTypes.string.isRequired,
  url: PropTypes.string,
};

Quote.defaultProps = {
  url: '#',
};

export default Quote;
