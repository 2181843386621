import PropTypes from 'prop-types';
import cn from 'classnames';
import { Image } from 'react-datocms';

import { IMAGE } from 'lib/constants';

import { Container } from 'components' 

const PageHero = ({ alt, responsiveImage, forHomePage, title }) => {
  // Accept custom alt text OR use data from Dato
  const altText = alt ? alt : responsiveImage.alt;

  const renderTitle = () => {
    if(!title) return <></>;

    const titleArray = title.split(' ');
    const lastWord = titleArray[titleArray.length - 1];
    const regularTitleText = titleArray.slice(0, titleArray.length - 1).join(' ');
    return (
      <Container>
        <div className="PageHero-title">
          <h1>
            {regularTitleText} {' '}
            <span className="highlight">{lastWord}</span>
          </h1>
        </div>
      </Container>
    )
  }

  return (
    <section className={cn('PageHero', { 'PageHero--home-page': forHomePage })}>
      { forHomePage && renderTitle() }
      <Image data={{ ...responsiveImage }} alt={altText} fadeInDuration={IMAGE.FADE_IN} className="PageHero-image" />
    </section>
  );
};

PageHero.propTypes = {
  alt: PropTypes.string,
  responsiveImage: PropTypes.object.isRequired,
  forHomePage: PropTypes.bool,
  title: PropTypes.string
};

export default PageHero;
