import PropTypes from 'prop-types';
import Link from 'next/link';
import { Container, Nav, Preview } from 'components';
import cn from 'classnames';

const Header = ({ preview, headerMenu }) => {
  return (
    <header className={cn('Header', { 'is-preview': preview })} data-datocms-noindex>
      <Preview preview={preview} />
      <Container>
        <Link
          href="/"
          className="Header-logo"
          title="The Property Group"
          aria-label="Link to Homepage">

          <div className="u-hiddenVisually">The Property Group</div>

        </Link>

        {headerMenu && <Nav items={headerMenu} />}
      </Container>
    </header>
  );
};

Header.propTypes = {
  preview: PropTypes.bool,
};

export default Header;
