import PropTypes from 'prop-types';

import Video from './video';

const VideoBlock = ({ title, video }) => {
  return (
    <div className="VideoBlock">
      {title && <h2>{title}</h2>}
      <Video video={video} wrapperClass="VideoBlock-video" />
    </div>
  );
};

VideoBlock.propTypes = {
  title: PropTypes.string.isRequired,
  video: PropTypes.object.isRequired,
};

export default VideoBlock;
