import { useEffect } from 'react';
import bamboohr from './bamboo-embed';

const BambooHRBlock = () => {
  useEffect(bamboohr);
  return (
    <div className="BambooBlock">
      <div id="BambooHR" data-domain="tpg.bamboohr.com" data-version="1.0.0" data-nopudgy></div>
    </div>
  );
};

export default BambooHRBlock;
