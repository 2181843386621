import PropTypes from 'prop-types';
import cn from 'classnames';
import parse from 'html-react-parser';
import { Image } from 'react-datocms';
import { IMAGE } from 'lib/constants';

const ImageTextBlock = ({ title, content, image, imageAlignment, headingStyle }) => {
  const left = imageAlignment === 'left';
  const right = imageAlignment === 'right';

  return (
    <div className={cn('ImageTextBlock', { 'ImageTextBlock--no-title': !title })}>
      <div className="Grid u-flex u-flexWrap u-flexAlignItemsStretch">
        <div
          className={cn(
            'Grid-cell u-md-width3of4 u-sm-mB-sm',
            { 'u-flexOrderLast': left },
            { 'u-flexOrderFirst': right }
          )}
        >
          {title && <h2 className={headingStyle ? headingStyle : ''}>{title}</h2>}
          {parse(content)}
        </div>
        <figure
          className={cn(
            'Grid-cell u-md-width1of4 u-sm-mB-sm',
            { 'u-flexOrderFirst': left },
            { 'u-flexOrderLast': right }
          )}
        >
          <Image
            data={{
              ...image.responsiveImage,
            }}
            alt={image.alt}
            fadeInDuration={IMAGE.FADE_IN}
            className="ImageTextBlock-image"
          />
        </figure>
      </div>
    </div>
  );
};

ImageTextBlock.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.object.isRequired,
  imageAlignment: PropTypes.oneOf(['left', 'right']),
};

export default ImageTextBlock;
