import PropTypes from 'prop-types';

const Office = ({ name, phoneNumber, poBox, addressUrl, addressText }) => {
  return (
    <address className="Office">
      <h3>{name}</h3>
      <ul className="Office-details">
        {/* === ADDRESS === */}
        <li className="Office-details-item">
          {/* <span className="icon icon-location"></span> */}
          <a href={addressUrl} title={`View ${name} office on map`} target="_blank" rel="noreferrer">
            {addressText}
          </a>
        </li>

        {/* === POSTAL === */}
        <li className="Office-details-item">
          {/* <span className="icon icon-mail"></span> */}
          <span aria-label="PO Box">{poBox}</span>
        </li>

        {/* === PHONE === */}
        <li className="Office-details-item">
          <strong>P: </strong>
          <a href={`tel:${phoneNumber}`} title={`Call the ${name} office`}>
            {phoneNumber}
          </a>
        </li>
      </ul>
    </address>
  );
};

Office.propTypes = {
  name: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  poBox: PropTypes.string.isRequired,
  addressUrl: PropTypes.string.isRequired,
  addressText: PropTypes.string.isRequired,
};

export default Office;
