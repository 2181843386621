import PropTypes from 'prop-types';
import classNames from 'classnames';

function Video({ video, wrapperClass }) {
  const { title, provider, providerUid } = video;
  switch (provider) {
    case 'youtube':
      return (
        <div className={classNames(wrapperClass, wrapperClass + `--youtube`)}>
          <iframe
            id="ytplayer"
            width="920"
            height="518"
            title={title}
            src={`https://www.youtube.com/embed/${providerUid}?modestbranding=1&playsinline=1&rel=1`}
            type="text/html"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      );
    case 'vimeo':
      return (
        <div className={classNames(wrapperClass, wrapperClass + `--vimeo`)}>
          <iframe
            src={`https://player.vimeo.com/video/${providerUid}?transparent=0`}
            width="920"
            height="518"
            title={title}
            type="text/html"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      );
    default:
      return (
        <div className="Alert Alert--error">
          <p className="small u-mB-0">
            <strong>Incompatible video provider!</strong>
            <br />
            <small>Supported types: `YouTube`, `Vimeo`</small>
          </p>
        </div>
      );
  }
}

Video.propTypes = {
  video: PropTypes.exact({
    height: PropTypes.number.isRequired,
    provider: PropTypes.oneOf(['youtube', 'vimeo', 'facebook']).isRequired,
    providerUid: PropTypes.string.isRequired,
    thumbnailUrl: PropTypes.string,
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
    width: PropTypes.number.isRequired,
  }).isRequired,
  wrapperClass: PropTypes.string,
};

Video.defaultProps = {
  wrapperClass: 'Video',
};

export default Video;
