const ChevronDownIcon = () => {
  return (
    <xml version="1.0" encoding="UTF-8">
      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.26 6.48"><polyline style={{
        fill: 'none',
        stroke: 'currentColor',
        strokeMiterlimit: 10,
        strokeWidth: '1.12px',
      }} 
      points=".4 .51 5.58 5.69 10.86 .4"/>
      </svg>
    </xml>
  )
}

export default ChevronDownIcon;