import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Alert = forwardRef(({ message, type, className }, ref) => {
  const isHappy = type === 'success' || type === 'info';
  const isSad = type === 'error' || type === 'warning';

  return (
    <div ref={ref} className={cn('Alert', `Alert--${type}`, className)}>
      <span className={cn('Alert-icon', 'icon', { 'icon-info': isHappy }, { 'icon-notification': isSad })} />
      <span>{message}</span>
    </div>
  );
});

Alert.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['error', 'success', 'warning', 'info']).isRequired,
};

Alert.displayName = 'Alert';

// Needs to have forwardRef for react-flip-list
export default Alert;
