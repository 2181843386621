import PropTypes from 'prop-types';
import { Container } from 'components';
import classNames from 'classnames';
import parse from 'html-react-parser';

const FeaturedIntro = ({ introduction }) => {
  return (
    <section className="FeaturedIntro">
      <Container>
        <div className="Grid">
          <div className="Grid-cell u-xl-width10of12 u-xl-before1of12 u-textCenter">
            <span
              className={classNames('u-mT-0', {
                'u-mB-md': introduction,
                'u-mB-0': !introduction,
              })}
            >
              {parse(introduction)}
            </span>
          </div>
        </div>
      </Container>
    </section>
  );
};

FeaturedIntro.propTypes = {
  introduction: PropTypes.string.isRequired,
};

export default FeaturedIntro;
