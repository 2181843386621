import { forwardRef } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { Image } from 'react-datocms';
import cn from 'classnames';
import { FormattedDate } from 'components';
import { IMAGE } from 'lib/constants';

const TileWrapper = ({ url, classNames, children, newTab }) => {
  if (!!url) {
    return (
      (<Link href={url} className={cn(classNames)} target={newTab ? '_blank' : ''}>

        {children}

      </Link>)
    );
  }

  return <div className={cn(classNames)}>{children}</div>;
};

const PageTileComponent = ({ classNames, title, image, url, date, introduction, newTab, portrait }, ref) => {
  return (
    <TileWrapper url={url} classNames={cn(`PageTile ${classNames?.trim() || ''}`, { 'PageTile--portrait' : portrait })} ref={ref} newTab={newTab}>
      {image && (
        <Image
          data={{
            ...image.responsiveImage,
          }}
          alt={image.alt}
          fadeInDuration={IMAGE.FADE_IN}
          className="PageTile-image"
        />
      )}
      <figcaption className="PageTile-caption">
        <h5 className="PageTile-caption-title">{title}</h5>
        {date && (
          <p className="PageTile-caption-date">
            Posted <FormattedDate dateString={date} />
          </p>
        )}
        {introduction && <div dangerouslySetInnerHTML={{ __html: introduction }} className="PageTile-caption-intro" />}
      </figcaption>
    </TileWrapper>
  );
};
PageTileComponent.displayName = 'PageTile';

const PageTile = forwardRef(PageTileComponent);

PageTile.propTypes = {
  classNames: PropTypes.string,
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  portrait: PropTypes.bool,
  date: (props, propName, componentName) => {
    if (
      !!props['classNames'] &&
      props['classNames'].includes('NewsTile') &&
      (props[propName] == undefined || typeof props[propName] != 'string')
    ) {
      return new Error(`Please provide field ${componentName} for NewsTile`);
    }
  },
  introduction: (props, propName, componentName) => {
    if (
      !!props['classNames'] &&
      props['classNames'].includes('NewsTile') &&
      (props[propName] == undefined || typeof props[propName] != 'string')
    ) {
      return new Error(`Please provide field ${componentName} for NewsTile`);
    }
  },
  newTab: PropTypes.bool,
};


export default PageTile;
