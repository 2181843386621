import PropTypes from 'prop-types';
import Link from 'next/link';

const Preview = ({ preview }) => {
  return preview ? (
    <div className="Preview">
      <p className="Preview-text">
        <strong>Preview Mode:</strong> This page is showing draft content.
      </p>
      <Link
        href="/api/exit-preview"
        className="Button Button--secondary Button--small">
        Exit
      </Link>
    </div>
  ) : null;
};

Preview.propTypes = {
  preview: PropTypes.bool,
};

export default Preview;
