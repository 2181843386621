/**
 * Embed Careers Page
 * BambooHR Version 1.0.0
 */

const bamboohr = () => {
  var rootId = 'BambooHR-ATS';
  var el = document.getElementById('BambooHR');

  if (!el) {
    console.error('BambooHR-ATS: Hmm, Looks like we are missing something, Double check the code snippet.');
    return;
  }

  var departmentId = el.getAttribute('data-departmentId');
  var domain = el.getAttribute('data-domain');
  var version = el.getAttribute('data-version') || '1.0.0';

  if (!domain) {
    console.error('BambooHR-ATS: We are unable to retrieve the domain, Double check the code snippet.');
    return;
  }

  var root = document.createElement('div');
  root.id = rootId;
  el.appendChild(root);

  var footer = document.createElement('div');
  footer.className = 'BambooHR-Footer';
  var poweredBy = document.createTextNode('Powered by');
  var logo = document.createElement('img');
  logo.src = 'https://resources.bamboohr.com/images/footer-logo.png';
  logo.alt = 'BambooHR - HR software';
  logo.style = 'display:inline;';
  var link = document.createElement('a');
  link.href = 'http://www.bamboohr.com';
  link.target = '_blank';
  link.rel = 'external';

  link.appendChild(logo);
  footer.appendChild(poweredBy);
  footer.appendChild(link);

  el.appendChild(footer);

  var embedUrl = 'https://' + domain + '/jobs/embed2.php?version=' + encodeURIComponent(version) + '';

  if (departmentId) {
    embedUrl += '&departmentId=' + encodeURIComponent(departmentId);
  }

  var xmlhttp = new XMLHttpRequest();
  xmlhttp.onreadystatechange = function () {
    if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
      var content = xmlhttp.responseText;
      if (root) {
        root.innerHTML = content;

        // remove style element and attributes
        let children = root.getElementsByTagName('*');
        let styles = root.getElementsByTagName('style');

        for (var i = 0; i < children.length; i++) {
          children[i].removeAttribute('style');
        }

        if (styles.length) {
          styles[0].remove();
        }
      }
    }
  };
  xmlhttp.open('GET', embedUrl, true);
  xmlhttp.send();
};

export default bamboohr;
