import PropTypes from 'prop-types';
import { Block, Container, InlineRecord, LinkToRecord } from 'components';
import { StructuredText } from 'react-datocms';

const GeneralPageBody = ({ content, children }) => {
  return (
    <Container>
      <div className="u-structuredText">
        <StructuredText
          data={content}
          renderInlineRecord={({ record }) => {
            return <InlineRecord record={record} />;
          }}
          renderLinkToRecord={(props) => {
            return <LinkToRecord {...props} />;
          }}
          renderBlock={({ record }) => {
            return <Block record={record} />;
          }}
        />
      </div>
      {children}
    </Container>
  );
};

GeneralPageBody.propTypes = {
  content: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default GeneralPageBody;
