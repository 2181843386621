export const parseSlug = (page) => {
  const { slug, __typename } = page || {};

  if (!slug) {
    return '/';
  }

  switch (__typename) {
    case 'HomePageRecord':
      return '/';
    case 'PeopleLandingPageRecord':
      return `/people`;
    case 'TeamMemberRecord':
      return `/people/${slug}`;
    case 'CaseStudiesLandingPageRecord':
      return `/our-work`;
    case 'CaseStudyRecord':
      return `/our-work/${slug}`;
    case 'NewsArticleLandingPageRecord':
      return `/news`;
    case 'NewsArticleRecord':
      return `/news/${slug}`;
    case 'ContactPageRecord':
      return `/contact`;
    case 'ServicesLandingPageRecord':
      return `/services`;
    case 'ServicePageRecord':
      return `/services/${slug}/`;
    default:
      return `/${slug}`;
  }
};

export const encode = (data) => {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
};
