import PropTypes from 'prop-types';
import { Image } from 'react-datocms';
import { IMAGE } from 'lib/constants';
import { Container } from 'components';

const PersonSummary = ({ bio, name, image, linkedIn, contactDetailsImage }) => {
  const hasDetails = contactDetailsImage || linkedIn;

  return (
    <div className="PersonSummary">
      <Container>
        <div className="Grid PersonSummary-content">
          <div className="Grid-cell u-md-width1of2">
            <div className="PersonSummary-profile">
              {image && (
                <Image
                  data={{
                    ...image.responsiveImage,
                  }}
                  alt={image.alt}
                  fadeInDuration={IMAGE.FADE_IN}
                  className="PersonSummary-profile-image"
                />
              )}
            </div>
          </div>
          <div className="Grid-cell u-md-width1of2">
            {bio && <div className="u-StructuredText" dangerouslySetInnerHTML={{ __html: bio }} />}
            {hasDetails && (
                <ul className="PersonSummary-profile-details">
                  {contactDetailsImage && (
                    <Image
                      data={{
                        ...contactDetailsImage.responsiveImage,
                      }}
                      alt={image.alt}
                      fadeInDuration={IMAGE.FADE_IN}
                      className="PersonSummary-profile-details--img "
                    />
                  )}
                  {linkedIn && (
                    <li>
                      <span className="icon icon-linkedin"></span>
                      <a href={linkedIn} aria-label={`${name} on LinkedIn`} target="_blank" rel="noreferrer">
                        LinkedIn
                      </a>
                    </li>
                  )}
                </ul>
              )}
          </div>
        </div>
      </Container>
    </div>
  );
};

PersonSummary.propTypes = {
  bio: PropTypes.string.isRequired,
  email: PropTypes.string,
  image: PropTypes.object.isRequired,
  linkedIn: PropTypes.string,
  name: PropTypes.string.isRequired,
  phone: PropTypes.string,
};

export default PersonSummary;
