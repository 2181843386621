const ArrowLargeIcon = () => {
  const styles = {
    fill: 'none',
    stroke: '#00dca7', // $teal
    strokeMiterlimit: 10,
    strokeWidth: '3.53px'
  }

  return (
    <xml version="1.0" encoding="UTF-8">
      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57.37 48.3" style={styles}>
        <line y1="24.15" x2="54.87" y2="24.15"/>
        <polyline points="32.12 1.25 54.87 24 31.82 47.05"/>
      </svg>
    </xml>
  )
}

export default ArrowLargeIcon;