import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-datocms';

import { IMAGE } from 'lib/constants';

const Author = ({ title, name, jobTitle, contactDetailsImage, linkedIn, responsiveImage }) => {
  const hasDetails = contactDetailsImage || linkedIn;

  return (
    <Fragment>
      <h2 className="u-mT-md u-lg-mT-lg">{title}</h2>
      <div className="Author">
        <Image
          data={{
            ...responsiveImage,
            alt: responsiveImage.alt,
          }}
          alt={responsiveImage.alt}
          fadeInDuration={IMAGE.FADE_IN}
          className="Author-image"
        />
        <div className="Author-details">
          <p className="name u-mB-0">{name}</p>
          { jobTitle && <p className="job-title u-mB-0">{jobTitle}</p> }
          {hasDetails && (
            <ul className="Author-details-list">
              {contactDetailsImage && (
                <Image
                  data={{
                    ...contactDetailsImage,
                  }}
                  alt={responsiveImage.alt}
                  fadeInDuration={IMAGE.FADE_IN}
                  className="Author-details-list--img "
                />
              )}
              {linkedIn && (
                <li>
                  <span className="icon icon-linkedin"></span>
                  <a href={linkedIn} aria-label={`${name} on LinkedIn`} target="_blank" rel="noreferrer">
                    LinkedIn Profile
                  </a>
                </li>
              )}
            </ul>
          )}
        </div>
      </div>
    </Fragment>
  );
};

Author.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  jobTitle: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  linkedIn: PropTypes.string,
  responsiveImage: PropTypes.object.isRequired,
};

Author.defaultProps = {
  title: 'Key contact',
};

export default Author;
