import { useState, useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FORM_COPY } from 'lib/constants';
import { Alert, Container } from 'components';
import cn from 'classnames';

const ContactPageForm = () => {
  const recaptchaRef = useRef(null);
  const [status, setStatus] = useState(null);

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(1, FORM_COPY.TOO_SHORT).max(50, FORM_COPY.TOO_LONG).required(FORM_COPY.REQ_FULL_NAME),
    phone: Yup.string()
      .required(FORM_COPY.REQ_PHONE)
      .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/, FORM_COPY.INVALID_PHONE),
    email: Yup.string().email(FORM_COPY.INVALID_EMAIL).required(FORM_COPY.REQ_EMAIL),
    recaptcha: Yup.string().required(FORM_COPY.REQ_ROBOT),
  });

  return (
    <Formik
      initialValues={{
        formName: 'contact',
        name: '',
        phone: '',
        email: '',
        message: '',
        recaptcha: '',
        honeyPot: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        actions.setStatus(undefined);

        const data = new FormData();

        Object.keys(values).forEach((k) => {
          data.append(k, values[k]);
        });

        data.append('firstName', values.name.split(' ').slice(0, -1).join(' '));
        data.append('lastName', values.name.split(' ').slice(-1).join(' '));

        await fetch('/api/forms/notifications', {
          method: 'POST',
          headers: new Headers({
            Accept: 'application/json',
          }),
          body: data,
        })
          .then((res) => {
            if (res.status >= 400 && res.status < 600) {
              setStatus({ sucess: false });
              actions.resetForm({});
              recaptchaRef.current.reset();
              actions.setErrors({ message: FORM_COPY.ERROR });
            } else {
              res.json().then((apiData) => {
                setStatus({ success: true });
                actions.resetForm({});
                recaptchaRef.current.reset();
                data.append('token', apiData?.token);

                fetch('/api/forms/submissions', {
                  method: 'POST',
                  headers: new Headers({
                    Accept: 'application/json',
                  }),
                  body: data,
                });
              });
            }
          })
          .catch(() => {
            actions.resetForm({});
            recaptchaRef.current.reset();
            setStatus({ success: false });
            actions.setErrors({ message: FORM_COPY.ERROR });
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        /* and other goodies */
      }) => (
        <section className="PageForm">
          <Container>
            <h1 className="u-mT-0">Send us a message</h1>
            <p>Contact us using the details below and we&apos;ll respond as soon as possible. (Required*)</p>

            {Object.keys(errors).length === 0 && !!status && !!status.success && (
              <Alert className="u-mB-md" type="success" message={FORM_COPY.SUCCESS} />
            )}

            {!!status && !status.success && <Alert className="u-mB-md" type="error" message={errors.message} />}

            <form
              className="PageForm-form Grid u-flex u-flexWrap u-flexAlignItemsStretch"
              name="contact"
              onSubmit={handleSubmit}
            >
              {/* ====== ROBOTS ====== */}
              <label htmlFor="honeyPot" className="u-hiddenVisually" hidden>
                Don’t fill this out if you’re human:
                <input name="honeyPot" />
              </label>

              {/* ====== NAME ====== */}
              <fieldset className="PageForm-form-field Grid-cell u-md-width1of2">
                <label
                  htmlFor="contact-name"
                  className={cn('Input Input--large Input--noLabel Input--teal Input--rounded-heavy', { 'has-error': errors.name && touched.name && errors.name })}
                >
                  <span className="Input-label is-required">Name</span>
                  <input
                    id="contact-name"
                    type="text"
                    name="name"
                    placeholder="Name*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name || ''}
                  />
                </label>

                <div className="Input-note">{errors.name && touched.name && errors.name}</div>
              </fieldset>

              {/* ====== PHONE ====== */}
              <fieldset className="PageForm-form-field Grid-cell u-md-width1of2">
                <label
                  htmlFor="contact-phone"
                  className={cn('Input Input--large Input--noLabel Input--teal Input--rounded-heavy', { 'has-error': errors.phone && touched.phone && errors.phone })}
                >
                  <span className="Input-label is-required">Phone</span>
                  <input
                    id="contact-phone"
                    type="text"
                    name="phone"
                    placeholder="Phone*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone || ''}
                  />
                </label>

                <div className="Input-note">{errors.phone && touched.phone && errors.phone}</div>
              </fieldset>

              {/* ====== EMAIL ====== */}
              <fieldset className="PageForm-form-field Grid-cell">
                <label
                  htmlFor="contact-email"
                  className={cn('Input Input--large Input--noLabel Input--teal Input--rounded-heavy', { 'has-error': errors.email && touched.email && errors.email })}
                >
                  <span className="Input-label is-required">Email</span>
                  <input
                    id="contact-email"
                    type="email"
                    name="email"
                    placeholder="Email*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email || ''}
                  />
                </label>

                <div className="Input-note">{errors.email && touched.email && errors.email}</div>
              </fieldset>

              {/* ====== MESSAGE ====== */}
              <fieldset className="PageForm-form-field Grid-cell">
                <label
                  htmlFor="contact-message"
                  className={cn('Textarea Textarea--teal Textarea--rounded-heavy Input--noLabel', { 'has-error': errors.message && touched.message && errors.message })}
                >
                  <span className="Input-label">Message</span>
                  <textarea
                    id="contact-message"
                    type="text"
                    name="message"
                    placeholder="Write your message..."
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.message || ''}
                  />
                </label>
              </fieldset>

              {/* ====== ReCAPTCHA ====== */}
              <fieldset className="PageForm-form-field Grid-cell u-md-width1of2 u-sm-flex u-sm-flexCol u-sm-flexAlignItemsCenter">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
                  onChange={(value) => setFieldValue('recaptcha', value)}
                />
                <div className="Input-note">{errors.recaptcha && touched.recaptcha && errors.recaptcha}</div>
              </fieldset>

              {/* ====== SUBMIT ====== */}
              <fieldset className="PageForm-form-field Grid-cell u-md-width1of2 u-textCenter u-md-textRight">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={cn('Button Button--primary-alt Button--medium', { 'is-disabled': isSubmitting })}
                >
                  {FORM_COPY.SUBMIT}
                </button>
              </fieldset>
            </form>
          </Container>
        </section>
      )}
    </Formik>
  );
};

export default ContactPageForm;