import { useState } from 'react';
import PropTypes from 'prop-types';
import { MultiSelect } from 'react-multi-select-component';

const MultiSelectFilter = ({ label, options, onChange, initial, isLoading }) => {
  const [selected, setSelected] = useState(initial || []);

  const elStrings = {
    allItemsAreSelected: 'All items are selected.',
    clearSearch: 'Clear Search',
    noOptions: 'No options',
    search: 'Search...',
    selectAll: 'Select All',
    selectSomeItems: 'Select...',
  };

  return (
    <>
      {label && <h4 id={`label-${label}`}>{label}</h4>}
      <MultiSelect
        options={options}
        value={selected}
        labelledBy={label ? `label-${label}` : ''}
        className="MultiSelect"
        overrideStrings={elStrings}
        disableSearch
        onChange={(value) => {
          setSelected(value);
          if (onChange) {
            onChange(value);
          }
        }}
        isLoading={isLoading}

        // Use this prop for debugging
        // isOpen
      />
    </>
  );
};

MultiSelectFilter.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  initial: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
};

export default MultiSelectFilter;
