import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, A11y } from 'swiper';
import { Quote, Container } from 'components';

import 'swiper/css';

const QuoteSlider = ({ title, quotes }) => {
  return (
    <div className="QuotesSlider">
      <Container>
        <h2 className="QuotesSlider-title">{title}</h2>
        <div className="QuotesSlider-slider">
          <Swiper
            slidesPerView={1}
            spaceBetween={15}
            grabCursor
            breakpoints={{
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            navigation={{
              nextEl: '.QuotesSlider-slider-btn--next',
              prevEl: '.QuotesSlider-slider-btn--prev',
            }}
            modules={[Navigation, A11y]}
          >
            {!!quotes &&
              quotes.map((quote) => {
                return (
                  <SwiperSlide key={quote.id}>
                    <Quote {...quote} />
                  </SwiperSlide>
                );
              })}
          </Swiper>
          <button className="QuotesSlider-slider-btn QuotesSlider-slider-btn--prev" type="button">
            <span className="icon icon-arrow-left" />
          </button>
          <button className="QuotesSlider-slider-btn QuotesSlider-slider-btn--next" type="button">
            <span className="icon icon-arrow-right" />
          </button>
        </div>
      </Container>
    </div>
  );
};

QuoteSlider.propTypes = {
  title: PropTypes.string.isRequired,
  quotes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default QuoteSlider;
