import { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import useKeypress from 'hooks/useKeypress';
import { SiteSearch } from 'components';
import MenuItem from './menu-item';

const Nav = ({ items }) => {
  const [searchActive, setSearchActive] = useState(false);
  const [mobileNavActive, setMobileNavActive] = useState(false);
  
  function toggleMobileNav(value) {
    setMobileNavActive(value)
    let element = document.body
    if(value){
      element.classList.add('menu-open')
    } else {
      element.classList.remove('menu-open')
    }
  }

  function toggleSearch() {
    setSearchActive(searchActive === false ? true : false);
  }

  // Closes the search on escape key press
  useKeypress('Escape', () => {
    if (searchActive) {
      toggleSearch();
    }
  });

  return (
    <Fragment>
      <nav id="site-nav" className={cn('Nav', { 'search-open': searchActive }, { 'nav-open': mobileNavActive })}>
        <div className="Nav-content">
          {/* Menu list */}
          <ul className="Nav-menu">
            {items?.map((item, index) => (
              <MenuItem key={`menu-item-${index}`} item={item} toggleMobileNav={toggleMobileNav} />
            ))}
          </ul>

          <SiteSearch searchActive={searchActive} toggleSearch={toggleSearch} />
        </div>
      </nav>

      <button
        className={cn('Hamburger', { 'is-active': mobileNavActive })}
        type="button"
        aria-expanded={mobileNavActive}
        aria-controls="site-nav"
        aria-label={mobileNavActive ? 'Close menu' : 'Open menu'}
        onClick={() => toggleMobileNav(!mobileNavActive)}
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </button>
    </Fragment>
  );
};

Nav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Nav;
