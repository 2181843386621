import PropTypes from 'prop-types';
import { Image } from 'react-datocms';

import { IMAGE } from 'lib/constants';

const ImageGalleryBlock = ({ title, images }) => {
  return (
    <div className="ImageGalleryBlock">
      {title && <h4 className="h2">{title}</h4>}
      <div className="Grid u-flex u-flexWrap u-flexAlignItemsStretch">
        {images.map((image) => (
          <figure key={image.alt} className="Grid-cell u-width1of2 u-lg-width1of4 u-mB-sm u-lg-mB-md ImageGalleryBlock-image">
            <Image
              data={{
                ...image.responsiveImage,
              }}
              fadeInDuration={IMAGE.FADE_IN}
              width={image.width}
              height={image.height}
              alt={image.alt}
            />
          </figure>
        ))}
      </div>
    </div>
  );
};

ImageGalleryBlock.propTypes = {
  title: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ImageGalleryBlock;
