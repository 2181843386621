import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, A11y } from 'swiper';
import { Organisation, Container } from 'components';

// TODO: replace default styles from swiper library?
import 'swiper/css';
import 'swiper/css/navigation';

const OrganisationSlider = ({ title, organisations }) => {
  return (
    <div className="OrgSlider">
      <Container>
        <h2 className="OrgSlider-title">{title}</h2>
        <div className="OrgSlider-slider">
          <Swiper
            slidesPerView={3}
            spaceBetween={15}
            grabCursor
            breakpoints={{
              1024: {
                slidesPerView: 5,
                spaceBetween: 30,
              },
            }}
            navigation={{
              nextEl: '.OrgSlider-slider-btn--next',
              prevEl: '.OrgSlider-slider-btn--prev',
            }}
            modules={[Navigation, A11y]}
          >
            {!!organisations &&
              organisations.map((organisation) => {
                return (
                  <SwiperSlide key={organisation.id}>
                    <Organisation {...organisation} />
                  </SwiperSlide>
                );
              })}
          </Swiper>
          <button className="OrgSlider-slider-btn OrgSlider-slider-btn--prev" type="button">
            <span className="icon icon-arrow-left" />
          </button>
          <button className="OrgSlider-slider-btn OrgSlider-slider-btn--next" type="button">
            <span className="icon icon-arrow-right" />
          </button>
        </div>
      </Container>
    </div>
  );
};

OrganisationSlider.propTypes = {
  title: PropTypes.string.isRequired,
  organisations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default OrganisationSlider;
