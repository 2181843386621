import PropTypes from 'prop-types';
import Link from 'next/link';
import { parseSlug } from 'lib/utils';

const LinkToRecord = ({ record, children, transformedMeta }) => {
  return (
    <Link href={parseSlug(record)} {...transformedMeta}>
      {children}
    </Link>
  );
};

LinkToRecord.propTypes = {
  record: PropTypes.object.isRequired,
  children: PropTypes.node,
  transformedMeta: PropTypes.object,
};

export default LinkToRecord;
