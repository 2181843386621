import PropTypes from 'prop-types';
import { parseSlug } from 'lib/utils';
import { PageTile } from 'components';
import cn from 'classnames';

const TileLinksBlock = ({ title, pages }) => {
  return (
    <div className="TileLinksBlock">
      {title && <h4 className="h2 u-mB-sm u-lg-mB-md">{title}</h4>}
      <div
        className={cn('Grid u-flex u-flexWrap u-flexAlignItemsStretch', { 'u-flexJustifyCenter': pages?.length < 3 })}
      >
        {pages.map((page) => {
          const url = page.__typename === 'MediaResourceRecord' ? page.asset?.url : parseSlug(page);
          return (
            <div className="Grid-cell u-md-width1of2 u-lg-width1of3 u-mB-sm u-lg-mB-md" key={`page-tile-${page.slug}`}>
              <PageTile
                title={page.title || page.name || ''}
                image={page.image}
                url={url}
                newTab={page.__typename === 'MediaResourceRecord'}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

TileLinksBlock.propTypes = {
  title: PropTypes.string,
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TileLinksBlock;
