import PropTypes from 'prop-types';
import { parseSlug } from 'lib/utils';
import Link from 'next/link';
import { ArrowLargeIcon } from '../svg-components';

import { Container, FooterNewsLetterForm } from 'components';

const Footer = ({ menu }) => {
  
  return (
    <footer className="Footer" data-datocms-noindex>
      <Container>
        <div className="Grid">
          
          <div className="Grid-cell">
            <Link href="/contact" passHref legacyBehavior>
              <div className="title">
                <h4 className="u-mT-0 title-text">
                  {"Let's Talk"}
                </h4>
                <div className="large-arrow">
                  <ArrowLargeIcon />
                </div>
              </div>
            </Link>
          </div>

          <div className="Grid-cell">
            <div className="Grid">
              <div className="Grid-cell u-lg-width1of3 u-mT-sm u-md-mT-lg u-lg-mT-0">
                <h4 className="Footer-strapline column-title u-mT-0">Building a stronger New Zealand, <span className="highlight">together.</span></h4>
              </div>

              <div className="Grid-cell u-xl-width1of3 u-mT-lg u-md-mT-lg u-lg-mT-0 u-xl-width1of3">
                {menu.contactTitle && <h4 className="column-title u-mT-0">{menu.contactTitle}</h4>}
                <ul className="Footer-contact">
                  {menu.contactPhoneNumber && (
                    <li>
                      <a
                        className="Footer-contact-link"
                        href={`tel:${menu.contactPhoneNumber}`}
                        aria-label={`Phone: ${menu.contactPhoneNumber}`}
                      >
                        {menu.contactPhoneNumber}
                      </a>
                    </li>
                  )}

                  {menu.contactEmail && (
                    <li>
                      <a
                        className="Footer-contact-link"
                        href={`mailto:${menu.contactEmail}`}
                        aria-label={`Email: ${menu.contactEmail}`}
                      >
                        {menu.contactEmail}
                      </a>
                    </li>
                  )}

                  {menu.officeLocations && (
                    <li>
                      <Link href={parseSlug(menu.officeLocations)} className="Footer-contact-link">
                        Office Locations
                      </Link>
                    </li>
                  )}

                  {menu.linkedIn && (
                    <li>
                      <a
                        className="Footer-contact-link"
                        href={menu.linkedIn}
                        aria-label="Company LinkedIn profile"
                        target="_blank"
                        rel="noreferrer"
                      >
                        LinkedIn
                      </a>
                    </li>
                  )}
                </ul>
              </div>

              {/* Note: hidden as part of the 2023 brand refresh  */}
              {/* <div className="Grid-cell u-lg-width1of3 u-mT-lg u-md-mT-lg u-lg-mT-0">
                <h4 className="column-title u-mT-0">Subscribe to our Newsletter</h4>
                <FooterNewsLetterForm />
              </div> */}
            </div>
          </div>
        </div>

        <section className="Footer-legal">
          
          <div className="legal-desktop">
            <p className="small u-mB-0">{menu.copyrightText}</p>
            <div className="Footer-legal-links">
              <Link href={parseSlug(menu.termsAndConditionsPage)}>Terms &amp; Conditions</Link>
              <Link href={parseSlug(menu.privacyPolicyPage)}>Privacy Policy</Link>
            </div>
          </div>

          <div className="legal-mobile">
            <div className="Footer-legal-links">
              <Link href={parseSlug(menu.termsAndConditionsPage)}>Terms &amp; Conditions</Link> | <Link href={parseSlug(menu.privacyPolicyPage)}>Privacy Policy</Link>
            </div>
            <p className="small u-mB-0">{menu.copyrightText}</p>
          </div>
        </section>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  menu: PropTypes.object.isRequired,
};

export default Footer;
