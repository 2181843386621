import PropTypes from 'prop-types';
import { Image } from 'react-datocms';

import { IMAGE } from 'lib/constants';
import Link from 'next/link';
import { parseSlug } from 'lib/utils';
import { StructuredText } from 'react-datocms';
import { LinkToRecord } from 'components';

const ImageBlock = ({ title, image, imageFit, imageFitted, headingStyle, button, description, hideCaption }) => {
  const descriptionHasValue = description?.value?.document?.children?.length > 0;
  const renderDescription = () => {
    return (
      <div className="u-structuredText ImageBlock-description">
        <StructuredText 
          data={description}
          renderLinkToRecord={(props) => {
            return <LinkToRecord {...props} />;
          }}
        />
      </div>
    )
  }

  const renderButton = () => {
    const buttonContent = button[0];
    const linkedPage = buttonContent.linkedPage?.[0];
    const isExternal = linkedPage.url;
    const className = "Button Button--tertiary"
    let linkElement;

    if(isExternal) {
      linkElement =  (
        <a href={linkedPage.url} className={className}>
          { buttonContent.label }
        </a>
      )
    }

    linkElement = (
      (<Link href={parseSlug(linkedPage.page)} className={className}>

        { buttonContent.label }

      </Link>)
    )

    return (
      <div className="ImageBlock-button-container">
        { linkElement }
      </div>
    )
  }

  return (
    <figure className="ImageBlock">
      {title && <h2 className={headingStyle ? headingStyle : ''}>{title}</h2>}
      <Image
        data={
          imageFit === 'Fit'
            ? {
                ...imageFitted.responsiveImage,
                height: Math.min((imageFitted.responsiveImage.height / imageFitted.responsiveImage.width) * 1160, 773),
              }
            : {
                ...image.responsiveImage,
              }
        }
        alt={image.alt}
        fadeInDuration={IMAGE.FADE_IN}
        className="ImageBlock-image"
      />
      { !hideCaption && image.title && <figcaption className="ImageBlock-caption">{image.title}</figcaption> }
      { descriptionHasValue && renderDescription() }
      { button?.length > 0 && renderButton() }
    </figure>
  );
};

ImageBlock.propTypes = {
  title: PropTypes.string,
  image: PropTypes.object.isRequired,
};

export default ImageBlock;
