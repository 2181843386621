import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Image } from 'react-datocms';
import { IMAGE } from 'lib/constants';
import cn from 'classnames';

const FullSizeImageTextBlock = ({ image, imageAlignment, text, backgroundColour }) => {
  const left = imageAlignment === 'left';
  const right = !left;

  return (
    <div className={cn('FullSizeImageTextBlock', { 'FullSizeImageTextBlock--bg-blue': backgroundColour === 'blue' })}>
      <div className="Grid u-flex u-flexWrap u-flexAlignItemsStretch FullSizeImageTextBlock-content">
        <div className={cn('Grid-cell u-sm-mB-sm u-md-width1of2',
          { 'u-flexOrderLast': left },
          { 'u-flexOrderFirst': right },
        )}>

          <div className="FullSizeImageTextBlock-text-content">
            { parse(text) }
          </div>
        </div>

        <figure className={cn(
          'Grid-cell u-sm-mB-sm FullSizeImageTextBlock-image-container u-md-width1of2',
          { 'u-flexOrderFirst': left },
          { 'u-flexOrderLast': right },
        )}>

          <Image
            data={{
              ...image.responsiveImage,
            }}
            alt={image.alt}
            fadeInDuration={IMAGE.FADE_IN}
            className="FullSizeImageTextBlock-image"
          />

        </figure>
      </div>
    </div>
  )
}

FullSizeImageTextBlock.propTypes = {
  image: PropTypes.object.isRequired,
  imageAlignment: PropTypes.oneOf(['left', 'right']),
  text: PropTypes.string,
  backgroundColour: PropTypes.oneOf(['blue', 'white']),
}

export default FullSizeImageTextBlock;