import PropTypes from 'prop-types';
import { StructuredText } from 'react-datocms';
import { LinkToRecord, InlineRecord, Block } from 'components';

const AlignTextBlock = ({ content, textAlignemnt }) => {
  return (
    <span className={`u-text${textAlignemnt}`}>
      <StructuredText
        data={content}
        renderInlineRecord={({ record }) => {
          return <InlineRecord record={record} />;
        }}
        renderLinkToRecord={(props) => {
          return <LinkToRecord {...props} />;
        }}
        renderBlock={({ record }) => {
          return <Block record={record} />;
        }}
      />
    </span>
  );
};

AlignTextBlock.propTypes = {
  content: PropTypes.object,
  textAlignemnt: PropTypes.oneOf(['Left', 'Center', 'Right', 'Justify']),
};

export default AlignTextBlock;
