import { useState, useRef } from 'react';
import Link from 'next/link';
import useKeypress from 'hooks/useKeypress';
import { parseSlug } from 'lib/utils';
import cn from 'classnames';
import { ChevronDownIcon } from '../svg-components';
import { useGlobalContext } from '../../lib/contexts/global-context';

const handleBlur = (e, onBlur) => {
  const currentTarget = e.currentTarget;

  setTimeout(() => {
    if (!currentTarget.contains(document.activeElement)) {
      // Manually disable focus for safari to correctly reconcile blur event triggers
      currentTarget.tabIndex = '-1';

      onBlur(e);
    }
  }, 0);
};

const MenuItem = ({ item, toggleMobileNav }) => {
  const [dropdownActive, setDropdownActive] = useState(false);
  const [dropdownHeight, setDropdownHeight] = useState('0px');
  const dropdownEl = useRef(null);
  const { currentNavTitle, setCurrentNavTitle } = useGlobalContext();

  function toggleDropdown(e, value) {

    // Manually focus to correctly trigger blur event for safari
    if(!dropdownActive && e.currentTarget) {
      e.currentTarget.focus();
    }

    setDropdownActive(value);
    setDropdownHeight(!value ? '0px' : `${dropdownEl.current.scrollHeight}px`);    
  }

  // Closes the dropdown on escape key press
  useKeypress('Escape', () => {
    if (dropdownActive) {
      toggleDropdown(false);
    }
  });

  const handleMenu =(selectedItem)=> {
    toggleMobileNav(false)
    const navTitle = selectedItem.title || item.title;
    setCurrentNavTitle(navTitle);
  }

  // Menu Item with Children
  if (item.pages.length > 1) {
    return (
      <li
        className={cn('Nav-menu-link', 'Nav-menu-link--dropdown', { 'is-active': dropdownActive, 'is-current': currentNavTitle?.toLowerCase() === item.title.toLowerCase() })}
        onBlur={(e) => handleBlur(e, () => toggleDropdown(e, false))}
      >
        <button
          type="button"
          aria-expanded={dropdownActive}
          aria-haspopup="true"
          aria-controls={`dropdown-${item.id}`}
          onClick={(e) => toggleDropdown(e, !dropdownActive)}
        >
          {item.title}
          <div className="icon">
            <ChevronDownIcon />
          </div>
        </button>
        <ul
          className="Nav-menu-dropdown"
          ref={dropdownEl}
          style={{ maxHeight: `${dropdownHeight}` }}
          id={`dropdown-${item.id}`}
          aria-hidden={!dropdownActive}
        >
          {item.pages.map((link) => (
            <li key={`sec-link-${link.slug}`} className="Nav-menu-dropdown-link">
              <Link
                href={parseSlug(link)}
                onClick={() => { handleMenu(item) }}
                tabIndex={!dropdownActive && '-1'}>
                {link.title || link.name}
              </Link>
            </li>
          ))}
        </ul>
      </li>
    );
  }

  // Default Menu Item
  const link = item.pages.length ? item.pages[0] : null;
  return (
    <li className={cn('Nav-menu-link', { 'is-current ': currentNavTitle?.toLowerCase() === item.title.toLowerCase() })}>
      <Link href={parseSlug(link || {})} onClick={handleMenu}>
        {item.title}
      </Link>
    </li>
  );
};

export default MenuItem;
