export const FORM_COPY = {
  ERROR: 'There was an error, please try again later.',
  SUCCESS: 'Thank you for your enquiry, someone will be in touch soon.',
  SUBSCRIBED: 'Thank you for subscribing! Please check your inbox to verify your email.',
  TOO_LONG: 'Too many characters.',
  TOO_SHORT: 'Not enough characters.',
  REQ_FULL_NAME: 'Name is required.',
  REQ_FIRST_NAME: 'First name is required.',
  REQ_LAST_NAME: 'Last name is required.',
  REQ_EMAIL: 'Email address is required.',
  REQ_PHONE: 'Phone number is required.',
  REQ_ROBOT: "Please verify you're human.",
  INVALID_EMAIL: 'Invalid email address.',
  INVALID_PHONE: 'Invalid phone number.',
  SUBMIT: 'Submit',
};

export const SEARCH_COPY = {
  PAGE_TITLE: 'Search Results',
  NO_RESULTS: 'Sorry, your search query did not return any results.',
  NO_FILTER_RESULTS: 'Sorry, your filter query did not return any results.',
  SHOW_RESULTS: 'Showing results for',
  ERROR: "We're sorry, something went wrong.",
  LOADING: 'Loading...',
  READ_MORE: 'Read more',
};

export const IMAGE = {
  FADE_IN: 300,
};

export const ERROR_PAGE = {
  TITLE_404: 'Page could not be found.',
  MESSAGE_404: "We're sorry, the page you have requested does not exist or has moved.",
  TITLE_5XX: 'An unexpected error has occurred.',
  MESSAGE_5XX: "We're sorry, something went wrong. Please try again later.",
};

export const PAGE_SIZE = 12;
