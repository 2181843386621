import PropTypes from 'prop-types';
import parseISO from 'date-fns/parseISO';
import intlFormat from 'date-fns/intlFormat';

const FormattedDate = ({ dateString }) => {
  const date = parseISO(dateString);
  return <time dateTime={dateString}>{intlFormat(date, {
    year: 'numeric',
    month:'long',
    day: 'numeric'
  },)}</time>;
};

FormattedDate.propTypes = {
  dateString: PropTypes.string.isRequired,
};

export default FormattedDate;
