import PropTypes from 'prop-types';
import Link from 'next/link';
import { Image } from 'react-datocms';

const Organisation = ({ image, url }) => {
  return (
    (<Link href={url} className="Organisation">

      <Image
        data={{
          ...image.responsiveImage,
          alt: image.responsiveImage.alt,
        }}
        fadeInDuration={150}
        alt={image.responsiveImage.alt}
        className="Organisation-logo"
      />

    </Link>)
  );
};

Organisation.propTypes = {
  image: PropTypes.object.isRequired,
  url: PropTypes.string,
};

Organisation.defaultProps = {
  url: '#',
};

export default Organisation;
