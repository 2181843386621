import PropTypes from 'prop-types';
import Link from 'next/link';
import { parseSlug } from 'lib/utils';

const InlineRecord = ({ record }) => {
  return (
    <Link href={parseSlug(record)}>
      {record.title || record.name}
    </Link>
  );
};

InlineRecord.propTypes = {
  record: PropTypes.shape({
    title: PropTypes.string,
    name: PropTypes.string,
    slug: PropTypes.string.isRequired,
  }),
};

export default InlineRecord;
