import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FORM_COPY } from 'lib/constants';
import { Alert } from 'components';
import cn from 'classnames';
import jsonp from 'jsonp';
import { encode } from 'lib/utils';

// NOTE: Use of this component has been removed as a part of the 2023 brand refresh project
//
// Just keeping this here in case it needs to be re-introduced into the site
const FooterNewsLetterForm = () => {
  const [status, setStatus] = useState(null);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().min(1, FORM_COPY.TOO_SHORT).max(50, FORM_COPY.TOO_LONG).required(FORM_COPY.REQ_FIRST_NAME),
    lastName: Yup.string().min(1, FORM_COPY.TOO_SHORT).max(50, FORM_COPY.TOO_LONG).required(FORM_COPY.REQ_LAST_NAME),
    email: Yup.string().email(FORM_COPY.INVALID_EMAIL).required(FORM_COPY.REQ_EMAIL),
  });

  const getAjaxUrl = (url) => url.replace('/post?', '/post-json?');

  return (
    <Formik
      initialValues={{
        formName: 'newsletter',
        firstName: '',
        lastName: '',
        email: '',
        recaptcha: 'not used',
        honeyPot: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        actions.setStatus(undefined);

        const { email, firstName, lastName } = values;
        const parsedValues = {
          EMAIL: email,
          FNAME: firstName,
          LNAME: lastName,
        };

        const params = encode({
          ...JSON.parse(JSON.stringify(parsedValues)),
        });

        const url = getAjaxUrl(process.env.NEXT_PUBLIC_MAILCHIMP_SIGNUP_URL) + '&' + params;

        jsonp(
          url,
          {
            param: 'c',
          },
          (err, data) => {
            
            if (err) {
              setStatus({ success: false });
              actions.resetForm({});
              actions.setErrors({ message: err.message });
            } else if (data.result !== 'success') {
              setStatus({ success: false });
              actions.resetForm({});
              actions.setErrors({ message: data.msg });
            } else {
              setStatus({ success: true });
              actions.resetForm({});
            }

            actions.setSubmitting(false);
          }
        );
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <>
          {Object.keys(errors).length === 0 && !!status && !!status.success && (
            <Alert className="Alert--small u-mB-sm" type="success" message={FORM_COPY.SUBSCRIBED} />
          )}

          {!!status && !status.success && (
            <Alert className="Alert--small u-mB-sm" type="error" message={errors.message} />
          )}

          <form className="Footer-form" name="newsletter" onSubmit={handleSubmit}>
            {/* ====== ROBOTS ====== */}
            <label htmlFor="honeyPot" className="u-hiddenVisually" hidden>
              Don’t fill this out if you’re human:
              <input name="honeyPot" />
            </label>

            {/* ====== EMAIL ====== */}
            <fieldset className="Footer-form-field u-mB-sm">
              <label
                htmlFor="contact-email"
                className={cn('Input', 'Input--small', 'Input--dark', 'Input--noLabel', {
                  'has-error': errors.email && touched.email && errors.email,
                })}
              >
                <span className="Input-label is-required">Email Address</span>
                <input
                  id="contact-email"
                  type="email"
                  name="email"
                  placeholder="Email..."
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email || ''}
                />
              </label>

              <div className="Input-note">{errors.email && touched.email && errors.email}</div>
            </fieldset>

            {/* ====== FIRST NAME ====== */}
            <fieldset className="Footer-form-field u-mB-sm">
              <label
                htmlFor="contact-firstName"
                className={cn('Input', 'Input--small', 'Input--dark', 'Input--noLabel', {
                  'has-error': errors.firstName && touched.firstName && errors.firstName,
                })}
              >
                <span className="Input-label is-required">First Name</span>
                <input
                  id="contact-firstName"
                  type="text"
                  name="firstName"
                  placeholder="First name..."
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName || ''}
                />
              </label>

              <div className="Input-note">{errors.firstName && touched.firstName && errors.firstName}</div>
            </fieldset>

            {/* ====== LAST NAME ====== */}
            <fieldset className="Footer-form-field u-mB-sm">
              <label
                htmlFor="contact-lastName"
                className={cn('Input', 'Input--small', 'Input--dark', 'Input--noLabel', {
                  'has-error': errors.lastName && touched.lastName && errors.lastName,
                })}
              >
                <span className="Input-label is-required">Last Name</span>
                <input
                  id="contact-lastName"
                  type="text"
                  name="lastName"
                  placeholder="Last name..."
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName || ''}
                />
              </label>

              <div className="Input-note">{errors.lastName && touched.lastName && errors.lastName}</div>
            </fieldset>

            {/* ====== SUBMIT ====== */}
            <fieldset className="Footer-form-field u-textRight">
              <button
                type="submit"
                disabled={isSubmitting}
                className={cn('Button Button--primary Button--small', { 'is-disabled': isSubmitting })}
              >
                {FORM_COPY.SUBMIT}
              </button>
            </fieldset>
          </form>
        </>
      )}
    </Formik>
  );
};

export default FooterNewsLetterForm;
