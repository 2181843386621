import PropTypes from 'prop-types';
import { Image } from 'react-datocms';
import cn from 'classnames';
import { Container } from 'components';
import { parseSlug } from 'lib/utils';
import { IMAGE } from 'lib/constants';

const PageIntro = ({ title, introduction, services, serviceIcon, isCaseStudy: isCaseStudyPage, isPersonPage }) => {
  return (
    <section className={cn('PageIntro PageIntro--horizontal', { 
      'PageIntro--services': services,
      'PageIntro--case-study': isCaseStudyPage,
      'PageIntro--person': isPersonPage
    })}>
      <Container>
        <div className="u-flex u-sm-flexWrap u-flexAlignItemsCenter u-flexJustifyBetween">
          <div className="PageIntro-content">
            <h1 className="u-mT-0 u-mB-0 title">{title}</h1>
            {introduction && (
              <div
                className={cn('u-structuredText lead u-mB-0 introduction', {
                  'u-mT-sm': isCaseStudyPage || isPersonPage

                })}
                dangerouslySetInnerHTML={{ __html: introduction }}
              />
            )}
          </div>

          {services && (
            <ul className="PageIntro-services">
              {services.map((service) => {
                if(!service.tagIcon) return;  
                return (
                  <li key={`service-${service.id}`}>
                    <a href={parseSlug(service)} title={service.tagIcon.alt || service.title}>
                      {service.tagIcon && (
                        <Image
                          data={service.tagIcon.responsiveImage}
                          alt={service.tagIcon.responsiveImage.alt}
                          fadeInDuration={IMAGE.FADE_IN}
                          className="PageIntro-services-icon u-sm-hidden"
                        />
                      )}
                      <p className="PageIntro-services-label">{service.tagIcon.alt || service.title}</p>
                    </a>
                  </li>
                  )
              })}
            </ul>
          )}

          {serviceIcon && (
            <ul className="PageIntro-services u-sm-hidden">
              <li>
                <Image
                  data={serviceIcon.responsiveImage}
                  alt={serviceIcon.responsiveImage.alt}
                  fadeInDuration={IMAGE.FADE_IN}
                  className="PageIntro-services-icon"
                />
              </li>
            </ul>
          )}
        </div>
      </Container>
    </section>
  );
};

PageIntro.propTypes = {
  title: PropTypes.string.isRequired,
  introduction: PropTypes.string,
  services: PropTypes.array,
  serviceIcon: PropTypes.object,
  isCaseStudyPage: PropTypes.bool,
  isPersonPage: PropTypes.bool
};

export default PageIntro;
