import Head from 'next/head';
import PropTypes from 'prop-types';
import { Header, Footer } from 'components';
import { useRouter } from 'next/router';

const Layout = ({ preview, children, menu }) => {
  const site = 'https://www.propertygroup.co.nz';
  const canonicalURL = site + useRouter().asPath;

  return (
    <>
      <Head>
        <link rel="canonical" hrefLang="en-nz" href={canonicalURL} />
      </Head>

      <Header preview={preview} headerMenu={menu?.headerMenu} />

      <main className="Page">{children}</main>

      {menu && <Footer menu={menu} />}
    </>
  );
};

Layout.propTypes = {
  preview: PropTypes.bool,
  children: PropTypes.node.isRequired,
  menu: PropTypes.object,
};

export default Layout;
