import PropTypes from 'prop-types';
import { Container } from 'components';
import Link from 'next/link';
import cn from 'classnames';

const PageCTA = ({ title, caption, btnLabel, btnURL }) => {
  return (
    <section className="PageCTA">
      <Container>
        <div className="Grid u-md-flex u-md-flexAlignItemsCenter">
          <div className="Grid-cell u-md-width2of3 u-textCenter u-md-textLeft u-mB-md u-md-mB-0">
            <h3
              className={cn('h1 u-mT-0', {
                'u-md-mB-0': !caption,
                'u-mB-sm': caption,
              })}
            >
              {title}
            </h3>
            {caption && <p className="lead u-mB-0">{caption}</p>}
          </div>
          <div className="Grid-cell u-md-width1of3 u-textCenter u-md-textRight">
            <Link
              href={btnURL}
              className="Button Button--secondary-outline Button--large Button--rounded-heavy">
              {btnLabel}
            </Link>
          </div>
        </div>
      </Container>
    </section>
  );
};

PageCTA.propTypes = {
  title: PropTypes.string.isRequired,
  caption: PropTypes.string,
  btnLabel: PropTypes.string.isRequired,
  btnURL: PropTypes.string.isRequired,
};

export default PageCTA;
