import PropTypes from 'prop-types';
import {
  ImageBlock,
  ImageGalleryBlock,
  TileLinksBlock,
  VideoBlock,
  BambooHRBlock,
  EmbedBlock,
  AlignTextBlock,
  ImageTextBlock,
  FullSizeImageTextBlock
} from 'components/blocks';

const Block = ({ record }) => {
  const components = {
    ImageBlockRecord: ImageBlock,
    ImageGalleryBlockRecord: ImageGalleryBlock,
    TileLinksBlockRecord: TileLinksBlock,
    VideoBlockRecord: VideoBlock,
    BambooHrBlockRecord: BambooHRBlock,
    IframeBlockRecord: EmbedBlock,
    TextAlignmentBlockRecord: AlignTextBlock,
    ImageTextBlockRecord: ImageTextBlock,
    FullSizeImageTextBlockRecord: FullSizeImageTextBlock
  };

  let key = record?.__typename;

  if (key in components) {
    const Component = components[key];
    return <Component {...record} />;
  }

  return null;
};

Block.propTypes = {
  record: PropTypes.object.isRequired,
};

export default Block;
