import PropTypes from 'prop-types';

const EmbedBlock = ({ url, title }) => (
  <section className="EmbedBlock">
    {title && <h2>{title}</h2>}
    <iframe className="EmbedBlock-iframe" title={title || 'embedded object'} scrolling="no" src={url} />
  </section>
);

EmbedBlock.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
};

export default EmbedBlock;
