import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { Formik } from 'formik';
import useFocus from 'hooks/useFocus';

const SiteSearch = ({ searchActive, toggleSearch }) => {
  const router = useRouter();
  const [inputRef, setInputFocus] = useFocus();

  const onClick = (e) => {
    e.preventDefault();
    toggleSearch();

    // Let the button recieve focus first, then switch focus
    setTimeout(() => {
      setInputFocus();
    }, 50);
  };

  return (
    <div className="Nav-search">
      {/* handles outside-click (shown only when open) */}
      <span className="Nav-search-overlay" onClick={onClick}></span>

      <button
        className="Nav-search-toggle"
        role="button"
        aria-controls="search-form"
        onClick={onClick}
        aria-expanded={searchActive}
      >
        <span className='u-block icon icon-search' />
        <span className="u-hiddenVisually">{searchActive ? 'Close search box' : 'Open search box'}</span>
      </button>

      <section className="Nav-search-form" id="search-form" aria-hidden={!searchActive}>
        <Formik
          initialValues={{
            siteSearch: '',
          }}
          onSubmit={(values) => {
            toggleSearch(false);

            router.push({
              pathname: '/search',
              query: {
                q: values.siteSearch,
              },
            });
          }}
        >
          {({ values, handleSubmit, handleChange, handleBlur }) => (
            <form className="SiteSearch" onSubmit={handleSubmit} autoComplete="off">
              <input
                ref={inputRef}
                className="SiteSearch-input"
                id="siteSearch"
                type="search"
                placeholder="Search..."
                aria-label="Search the website content"
                spellCheck="true"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.siteSearch || ''}
              />
              {/* TODO: Designs don't have this button, but then there is no way to indicate how to submit a search? */}
              <button className="SiteSearch-submit" type="submit">
                <span className="icon icon-search"></span>
                <span className="u-hiddenVisually">Search</span>
              </button>
            </form>
          )}
        </Formik>
      </section>
    </div>
  );
};

SiteSearch.propTypes = {
  searchActive: PropTypes.bool.isRequired,
  toggleSearch: PropTypes.func.isRequired,
};

export default SiteSearch;
